.pel_buy:hover {
  box-shadow: 0px 0px 16px 3px rgba(140, 70, 255, 0.42);
  backdrop-filter: blur(81.5485px);
}
.pel_buy_bottom {
  display: none;
}
.pel_buy:hover .pel_buy_bottom {
  display: flex;
}
.main_buy_pel_inner:hover .buy_pel_hover {
  opacity: 1 !important;
}
